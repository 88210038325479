import './account-edit.css';
import React from "react";
import {AccountEditProps} from "./account-edit.props";
import {serviceUserApi, UserDto} from "@coma/api/users";
import {BlockSharp, StartSharp} from "@mui/icons-material";
import useServiceApi from "../../../hooks/useServiceApi";
import {Button, Error, Header} from "../../../components";
import {AccountDetails} from "../../account/account-details/account-details";

/**
 * Account management
 */
export const AccountEdit = function AccountEditComponent(props: AccountEditProps) {
    const { account, onReload, onBack, onLogout } = props
    const {
        execute: executeUpdateUser,
        error: errorUpdateUser
    } = useServiceApi<UserDto>(serviceUserApi.updateUser);

    const onUpdateUser = (update: Partial<UserDto>, waitingString: string) => {
        executeUpdateUser(update, waitingString)
            .then(() => {
                onReload();
            })
    }

    return <div className={"account-edit"}>
        {
            errorUpdateUser ?
                <Error errorTx={errorUpdateUser} tryAgainAction={executeUpdateUser} onLogout={onLogout}/>
                :
                <>
                    <Header title="Accounts" onBack={onBack}/>
                    {
                        account.disabled ?
                            <div className={"account-edit__row"}>
                                <span>Enable user</span>
                                <Button icon={StartSharp}
                                        onClick={() => {
                                            onUpdateUser(
                                                {
                                                    id: account.id,
                                                    disabled: "",
                                                },
                                                "enable-user",
                                            );
                                        }}
                                        noPadding
                                />
                            </div>
                            :
                            <div className={"account-edit__row"}>
                                <span>Disable user</span>
                                <Button icon={BlockSharp}
                                        onClick={() => {
                                            onUpdateUser(
                                                {
                                                    id: account.id,
                                                    disabled: "true",
                                                },
                                                "disable-user",
                                            );
                                        }}
                                        noPadding
                                />
                            </div>
                    }
                    <div className={"content__splitter"}/>
                    <AccountDetails account={account}/>
                </>
        }
    </div>
}

import * as React from 'react';

interface IErrorBoundaryProps {
    onError: () => void;
    children: React.ReactNode;
}

interface IErrorBoundaryState {
    error?: Error;
    errorInfo?: React.ErrorInfo;
}

export class ErrorBoundary extends React.Component<IErrorBoundaryProps, IErrorBoundaryState> {
    constructor(props: IErrorBoundaryProps) {
        super(props);
        this.state = { error: undefined, errorInfo: undefined };
    }

    componentDidCatch(error: Error , errorInfo: React.ErrorInfo) {
        // Catch errors in any components below and re-render with error message
        this.setState({
            error,
            errorInfo
        })
        // You can also log error messages to an error reporting service here
    }

    render() {
        const {error, errorInfo} = this.state;
        if (errorInfo) {
            return (
                <div>
                    <h2>Something went wrong.</h2>
                    <div style={{ whiteSpace: 'pre-wrap' }}>
                        {error && error.toString()}
                        <br />
                        {errorInfo.componentStack}
                    </div>
                </div>
            );
        }
        // Normally, just render children
        return this.props.children;
    }
}

import './dashboard.css';
import {DashboardProps} from "./dashboard.props";
import React, {useState} from "react";
import {ShowChartSharp, NotificationsSharp, RuleSharp, Twitter} from '@mui/icons-material';
import { AppModules } from '../../modules/app.modules';
import {Exchanges, Notifications, Tweets, Rules} from "../../modules";
import {Button} from "../../components";

/**
 * Welcome page
 */
export const Dashboard = function DashboardPage(props: DashboardProps) {
    const { defaultAppModule, autoRefreshEnabled, smsEnabled, licenseType, showToolbar = true, setActiveAppModule, onLogout, children } = props;
    const [ openModule, setOpenModule ] = useState<AppModules>(defaultAppModule)
    const [ loadCount, setLoadCount ] = useState<number>(0)

    const onOpenRulesModule = () => {
        setOpenModule(AppModules.RULES);
        setLoadCount(loadCount + 1);
    }
    const onOpenExchangesModule = () => {
        setOpenModule(AppModules.EXCHANGES);
        setLoadCount(loadCount + 1);
    }
    const onOpenNotificationsModule = () => {
        setOpenModule(AppModules.NOTIFICATIONS);
        setLoadCount(loadCount + 1);
    }
    const onOpenTweetsModule = () => {
        setOpenModule(AppModules.TWEETS);
        setLoadCount(loadCount + 1);
    }

    return <div className={"dashboard"}>
        {
            showToolbar
            && <div className={"dashboard__toolbar"}>
                <Button icon={RuleSharp} onClick={onOpenRulesModule}
                        highlight={openModule === AppModules.RULES}
                        blink={autoRefreshEnabled && openModule === AppModules.RULES}
                />
                <Button icon={ShowChartSharp} onClick={onOpenExchangesModule}
                        highlight={openModule === AppModules.EXCHANGES}
                        blink={autoRefreshEnabled && openModule === AppModules.EXCHANGES}
                />
                <Button icon={NotificationsSharp} onClick={onOpenNotificationsModule}
                        highlight={openModule === AppModules.NOTIFICATIONS}
                        blink={autoRefreshEnabled && openModule === AppModules.NOTIFICATIONS}
                />
                <Button icon={Twitter} onClick={onOpenTweetsModule}
                        highlight={openModule === AppModules.TWEETS}
                        blink={autoRefreshEnabled && openModule === AppModules.TWEETS}
                />
            </div>
        }
        <div className={"dashboard__content"}>
            {children}
            {openModule === AppModules.RULES ?
                <Rules loadCount={loadCount}
                       autoRefreshEnabled={autoRefreshEnabled}
                       smsEnabled={smsEnabled}
                       setActiveAppModule={setActiveAppModule}
                       onLogout={onLogout}
                />
                : undefined
            }
            {openModule === AppModules.EXCHANGES ?
                <Exchanges loadCount={loadCount}
                           autoRefreshEnabled={autoRefreshEnabled}
                           licenseType={licenseType}
                           setActiveAppModule={setActiveAppModule}
                           onLogout={onLogout}
                />
                : undefined
            }
            {openModule === AppModules.NOTIFICATIONS ?
                <Notifications loadCount={loadCount}
                               autoRefreshEnabled={autoRefreshEnabled}
                               setActiveAppModule={setActiveAppModule}
                               onLogout={onLogout}
                />
                : undefined
            }
            {openModule === AppModules.TWEETS ?
                <Tweets loadCount={loadCount}
                        autoRefreshEnabled={autoRefreshEnabled}
                        setActiveAppModule={setActiveAppModule}
                        onLogout={onLogout}
                />
                : undefined
            }
        </div>
    </div>
}

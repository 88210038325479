import {ModelDto} from "@coma/api/common/model.dto";
import {Coin, Exchange} from "@coma/api/exchanges";
import {Operation} from "@coma/api/rules/dto/operation";

export interface SelectProps {

    labelTx: string;

    value: string;

    options: SelectOptions[],

    onChange: (newValue: string) => void;
}

export interface SelectOptions {
    value: string;
    label: string;
}


export const ALL_KEYWORD_OPTION = "ALL";
export const getKeywordOptions = (modelsDto: ModelDto[]): SelectOptions[] => {
    const keywords = new Set<string>();
    modelsDto.forEach((modelDto) => keywords.add(modelDto.keyword));
    const options: SelectOptions[] = [];
    const option: SelectOptions = {
        value: ALL_KEYWORD_OPTION,
        label: ALL_KEYWORD_OPTION,
    };
    options.push(option);
    keywords.forEach((keyword) => {
        const option: SelectOptions = {
            value: keyword,
            label: keyword.toUpperCase(),
        };
        options.push(option);
    });
    return options;
}

export const getExchangeOptions = (filterOut?: Exchange): SelectOptions[] => {
    const options: SelectOptions[] = [];
    Object.keys(Exchange).forEach((key) => {
        // @ts-ignore
        const value = Exchange[key]
        if (!filterOut || value !== filterOut) {
            const option: SelectOptions = {
                value,
                label: key,
            };
            options.push(option);
        }
    });
    return options;
}

export const getCoinOptions = (filterOut?: Coin): SelectOptions[] => {
    const options: SelectOptions[] = [];
    Object.keys(Coin).forEach((key) => {
        // @ts-ignore
        const value = Coin[key]
        if (!filterOut || value !== filterOut) {
            const option: SelectOptions = {
                value,
                label: key,
            };
            options.push(option);
        }
    });
    return options;
}

export const getOperationOptions = (): SelectOptions[] => {
    const options: SelectOptions[] = [];
    Object.keys(Operation).forEach((key) => {
        // @ts-ignore
        const value = Operation[key]
        const option: SelectOptions = {
            value,
            label: key,
        };
        options.push(option);
    });
    return options;
}

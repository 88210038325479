import './exchanges.css';
import {ExchangesProps} from "./exchanges.props";
import React, {useCallback, useEffect, useState} from "react";
import {ExchangePricesDto, serviceExchangeApi} from "@coma/api/exchanges";
import {AppModules} from "../app.modules";
import useServiceApi from "../../hooks/useServiceApi";
import {
    ALL_KEYWORD_OPTION,
    EmptyList,
    Error,
    getKeywordOptions,
    ProgressIndicator,
    Select,
} from "../../components";

/**
 * Exchanges module
 */
export const Exchanges = function ExchangesModule(props: ExchangesProps) {
    const { loadCount, autoRefreshEnabled, licenseType, setActiveAppModule, onLogout } = props;
    const [ exchangesPrices, setExchangesPrices ] = useState<ExchangePricesDto[]>();
    const [ keyword, setKeyword ] = useState<string>(ALL_KEYWORD_OPTION);
    const {
        execute: executeGetExchangesPrices,
        waiting: waitingGetExchangesPrices,
        error: errorGetExchangesPrices
    } = useServiceApi<ExchangePricesDto[]>(serviceExchangeApi.getExchangesPrices);

    const getExchangesPrices = useCallback(() => {
        executeGetExchangesPrices()
            .then((exchangesPricesDto) => {
                setExchangesPrices(exchangesPricesDto);
            })
    }, [ executeGetExchangesPrices ])

    useEffect(() => {
        setActiveAppModule(AppModules.EXCHANGES);
    }, [ setActiveAppModule, loadCount ]);

    useEffect(() => {
        getExchangesPrices();
        if (autoRefreshEnabled) {
            const interval = setInterval(getExchangesPrices, 1000 * 30);
            return () => clearInterval(interval);
        }
    }, [ autoRefreshEnabled, getExchangesPrices ]);

    return <div className={"exchanges"}>
        {waitingGetExchangesPrices ?
            <ProgressIndicator/>
            :
            errorGetExchangesPrices ?
                <Error errorTx={errorGetExchangesPrices} tryAgainAction={getExchangesPrices} onLogout={onLogout}/>
                :
                exchangesPrices && exchangesPrices.length ?
                    <>
                        <Select labelTx={"Coin filter"}
                                value={keyword}
                                options={getKeywordOptions(exchangesPrices)}
                                onChange={(selectedKeyword: string) => setKeyword(selectedKeyword)}
                        />
                        <div className={"content__splitter"}/>
                        {
                            exchangesPrices.map((exchangePrices) => {
                                if (keyword !== ALL_KEYWORD_OPTION && keyword !== exchangePrices.keyword) {
                                    return undefined;
                                }
                                let maxPrice = -1;
                                let minPrice = 1000;
                                exchangePrices.prices.forEach((p)=> {
                                    if (maxPrice < p.buyPrice) {
                                        maxPrice = p.buyPrice;
                                    }
                                    if (minPrice > p.buyPrice) {
                                        minPrice = p.buyPrice;
                                    }
                                });
                                const minMaxDiff = Math.abs( ( ( maxPrice - minPrice ) / ( ( maxPrice + minPrice ) / 2 ) ) * 100 );
                                return <div key={exchangePrices.utcTime}>
                                    <div className={"exchanges__prices"}>
                                        <div className={"exchanges__prices__info"}>
                                            license: {licenseType}
                                        </div>
                                        <div className={"exchanges__prices__time-column"}>
                                            <div>
                                                {exchangePrices.coin} ({minMaxDiff.toFixed(1)}%)
                                            </div>
                                            <div className={"exchanges__prices__time"}>
                                                {new Date(exchangePrices.utcTime).toLocaleTimeString()}
                                            </div>
                                        </div>
                                        <div className={"exchanges__prices__value-column"}>
                                            {exchangePrices.prices && exchangePrices.prices.map((exchangePrice) => {
                                                const buyPrice = exchangePrice.buyPrice;
                                                const rowOfInterest = minMaxDiff > 18 && (buyPrice === minPrice || buyPrice === maxPrice);
                                                const rowClass = rowOfInterest ? "exchanges__prices__row-of-interest" : "exchanges__prices__row";
                                                return <div className={rowClass}
                                                            key={exchangePrice.exchange}>
                                                    <div className={"exchanges__prices__exchange"}>
                                                        [{exchangePrice.origin}] {exchangePrice.exchange}:
                                                    </div>
                                                    {buyPrice}
                                                </div>
                                            })}
                                        </div>
                                    </div>
                                    <div className={"content__splitter"}/>
                                </div>
                            })
                        }
                    </>
                    :
                    <EmptyList titleTx={"No exchanges prices..."}/>
        }
    </div>
}

import {AxiosRequestConfig} from "axios";
import {axios_get} from "../../common/services/api.configuration";
import {TweetDto} from "../dto/tweet.dto";

export const TWEETS_ROUTE = "/tweets"

class TweetsApi {

    getTweets(): Promise<TweetDto[]> {
        const config: AxiosRequestConfig = {};
        return axios_get(TWEETS_ROUTE, config, mapToTweetDto);
    }
}

const mapToTweetDto = (data: any): TweetDto[] => {
    return data;
}

export const serviceTweetsApi = new TweetsApi();

import './price-diff-rule.css';
import React, {useState} from "react";
import {PriceDiffRuleProps} from "./price-diff-rule.props";
import {
    Button,
    getCoinOptions,
    getExchangeOptions,
    Select,
    TextField,
    TextFieldPropsType
} from "../../../../components";
import {Coin, Exchange} from "@coma/api/exchanges";
import {Save} from "@mui/icons-material";

/**
 * Price diff rule
 */
export const PriceDiffRule = function PriceDiffRuleComponent(props: PriceDiffRuleProps) {
    const { rule, isFastEdit, updateRule, waiting } = props
    const [ priceDiffInPercent, setPriceDiffInPercent ] = useState<number>(rule.priceDiffInPercent);
    const [ coin, setCoin ] = useState<Coin>(rule.coin);
    const [ fromExchange, setFromExchange ] = useState<Exchange>(rule.fromExchange);
    const [ toExchange, setToExchange ] = useState<Exchange>(rule.toExchange);
    const updatePercentageButtonRef = React.useRef();
    const updateRuleButtonRef = React.useRef();

    return <div className={"price-diff-rule"}>
        <div className={"price-diff-rule__row "}>
            <div className={"price-diff-rule__field__dif-in-percent"}>
                <TextField labelTx={"Price diff in percent"}
                           type={TextFieldPropsType.number}
                           value={priceDiffInPercent}
                           onChange={(newValue, finish) => {
                               const percent = Number.parseInt(newValue)
                               setPriceDiffInPercent(percent)
                               if (finish) {
                                   if (isFastEdit) {
                                       // @ts-ignore
                                       updatePercentageButtonRef.current?.focus();
                                   } else {
                                       // @ts-ignore
                                       updateRuleButtonRef.current?.focus();
                                   }
                               }
                           }}
                />
            </div>
            {
                isFastEdit
                && <Button titleTx="Update % diff"
                           forwardedRef={updatePercentageButtonRef}
                           waiting={waiting === "update-percentage"}
                           onClick={() => {
                               updateRule(
                                   {
                                       id: rule.id,
                                       priceDiffInPercent,
                                   },
                                   "update-percentage",
                               );
                           }}
                />
            }
        </div>
        <div className={"content__devider"}/>
        <Select labelTx={"Coin"}
                value={coin}
                options={getCoinOptions()}
                onChange={(newValue: string) => {
                    if (isFastEdit) {
                        updateRule(
                            {
                                id: rule.id,
                                coin: newValue as Coin,
                            },
                            "update-coin",
                        );
                    } else {
                        setCoin(newValue as Coin)
                    }
                }}
        />
        <Select labelTx={"From exchange"}
                value={fromExchange}
                options={getExchangeOptions(toExchange)}
                onChange={(newValue: string) => {
                    if (isFastEdit) {
                        updateRule(
                            {
                                id: rule.id,
                                fromExchange: newValue as Exchange,
                            },
                            "update-from-exchange",
                        );
                    } else {
                        setFromExchange(newValue as Exchange)
                    }
                }}
        />
        <Select labelTx={"To exchange"}
                value={toExchange}
                options={getExchangeOptions(fromExchange)}
                onChange={(newValue: string) => {
                    if (isFastEdit) {
                        updateRule(
                            {
                                id: rule.id,
                                toExchange: newValue as Exchange,
                            },
                            "update-to-exchange",
                        );
                    } else {
                        setToExchange(newValue as Exchange)
                    }
                }}
        />
        {
            !isFastEdit
            && <div className={"price-diff-rule__update"}>
                <Button forwardedRef={updateRuleButtonRef}
                        icon={Save}
                        onClick={() => {
                            updateRule(
                                {
                                    id: rule.id,
                                    priceDiffInPercent,
                                    toExchange,
                                    fromExchange,
                                },
                                "update-rule",
                            );
                        }}
                />
            </div>
        }
    </div>
}

import './settings.css';
import {getScreenType, ScreenType, setScreenType} from "@coma/api/utils/screen-type";
import {SettingsProps} from "./settings.props";
import React, {useCallback, useEffect, useState} from "react";
import {Snackbar} from "@mui/material";
import {ClearSharp, ListSharp} from "@mui/icons-material";
import {isSmsEnabled} from "@coma/api/utils/general-utils";
import {serviceSettingsApi, SettingsDto} from "@coma/api/settings";
import {getServerInstance, setServerInstance} from "@coma/api/common/services/api.configuration";
import {Server} from "@coma/api/common/services/server";
import {AppModules, getAppModuleHeader} from '../app.modules';
import useServiceApi from "../../hooks/useServiceApi";
import {Button, Error, Header, ProgressIndicator, Select, SelectOptions, Switch} from "../../components";
import {Accounts} from "../accounts/accounts";

/**
 * Settings module
 */
export const Settings = function SettingsModule(props: SettingsProps) {
    const { setActiveAppModule, lastActiveAppModule, licenseType, onBack, onLogout, children } = props;
    const [ settings, setSettings ] = useState<SettingsDto>();
    const [ openAccountList, setOpenAccountList ] = useState<boolean>(false);
    const [ snackbarMessage, setSnackbarMessage ] = useState<string>();
    const backTitle = getAppModuleHeader(lastActiveAppModule);
    const smsIsNotLicensed = !isSmsEnabled(licenseType);

    const {
        execute: executeGetSettings,
        waiting: waitingGetSettings,
        error: errorGetSettings
    } = useServiceApi<SettingsDto>(serviceSettingsApi.getSettings);
    const {
        execute: executeUpdateSettings,
        waiting: waitingUpdateSettings,
        error: errorUpdateSettings,
        setError: setUpdateSettingsError,
    } = useServiceApi<SettingsDto>(serviceSettingsApi.updateSettings);
    const {
        execute: executeTestSms,
        waiting: waitingTestSms,
        error: errorTestSms
    } = useServiceApi<boolean>(serviceSettingsApi.testSms);

    const settingsError = errorGetSettings || errorUpdateSettings;

    const loadPage = useCallback(() => {
        executeGetSettings()
            .then((settingsDto) => {
                setSettings(settingsDto);
            })
    }, [ executeGetSettings ]);

    useEffect(() => {
        setActiveAppModule(AppModules.SETTINGS);
        loadPage();
    }, [ setActiveAppModule, loadPage ]);

    const onUpdateSettings = (update: Partial<SettingsDto>, waitingString: string) => {
        executeUpdateSettings(update, waitingString)
            .then((settingsDto) => {
                setSettings(settingsDto);
                if (ScreenType.WEB === getScreenType()) {
                    onBack();
                }
            })
    }

    const onTestSms = () => {
        executeTestSms()
            .then((sent) => {
                setSnackbarMessage(`Sms sent ${sent}`);
            })
    }

    return <div className={"settings"}>
        <div className={"settings__content"}>
            {
                children ?
                    children
                    : <Header title={backTitle} onBack={onBack}/>
            }
            {waitingGetSettings ?
                <ProgressIndicator/>
                :
                settingsError || errorTestSms ?
                    <>
                        {
                            settingsError &&
                            <Error errorTx={settingsError}
                                   tryAgainAction={() => {
                                       setUpdateSettingsError("");
                                       loadPage();
                                   }}
                                   onLogout={onLogout}
                            />
                        }
                        {
                            errorTestSms &&
                            <Error errorTx={errorTestSms} tryAgainAction={executeTestSms} onLogout={onLogout}/>
                        }
                    </>
                    :
                    openAccountList ?
                        <Accounts onBack={() => setOpenAccountList(false)} onLogout={onLogout}/>
                        :
                        settings && <>
                            {
                                settings.user
                                && <>
                                    <div className={"settings__row"}>
                                        <Switch labelTx={"Enable auto refresh"}
                                                waiting={waitingUpdateSettings === "enable-auto-refresh"}
                                                checked={!!settings.user.autoRefreshEnabled}
                                                onChange={(checked) => {
                                                    onUpdateSettings(
                                                        {
                                                            user: {
                                                                autoRefreshEnabled: checked,
                                                            }
                                                        },
                                                        "enable-auto-refresh",
                                                    );
                                                }}
                                        />
                                    </div>
                                    <div className={"content__devider"}/>
                                    <div className={"settings__multi-row"}>
                                        <div className={"settings__row"}>
                                            <Switch labelTx={"Enable sms"}
                                                    waiting={waitingUpdateSettings === "enable-sms"}
                                                    disabled={smsIsNotLicensed}
                                                    checked={!!settings.user.smsEnabled}
                                                    onChange={(checked) => {
                                                        onUpdateSettings(
                                                            {
                                                                user: {
                                                                    smsEnabled: checked,
                                                                }
                                                            },
                                                            "enable-sms",
                                                        );
                                                    }}
                                            />
                                            <Button titleTx="Test SMS"
                                                    disabled={smsIsNotLicensed}
                                                    waiting={!!waitingTestSms}
                                                    onClick={onTestSms}
                                            />
                                        </div>
                                        {
                                            smsIsNotLicensed
                                            && <div className={"license_locked"}>
                                                Upgrade license to unlock the feature
                                            </div>
                                        }
                                    </div>
                                    <div className={"content__devider"}/>
                                    <Select labelTx={"Server instance"}
                                            value={getServerInstance()}
                                            options={getServerOptions()}
                                            onChange={(newValue: string) => {
                                                const newServer = newValue as Server;
                                                setServerInstance(newServer);
                                                loadPage();
                                            }}
                                    />
                                    <div className={"content__devider"}/>
                                    <Select labelTx={"Screen type"}
                                            value={getScreenType()}
                                            options={getScreenTypeOptions()}
                                            onChange={(newValue: string) => {
                                                const newScreenType = newValue as ScreenType;
                                                setScreenType(newScreenType);
                                                setActiveAppModule(AppModules.EXCHANGES);
                                            }}
                                    />
                                    <div className={"content__splitter"}/>
                                </>
                            }
                            {
                                settings.system
                                && <>
                                    <div className={"settings__system"}>
                                        <div className={"settings__row"}>
                                            <div className={"settings__label"}>
                                                All users
                                            </div>
                                            <Button icon={ListSharp}
                                                    waiting={waitingUpdateSettings === "all-users"}
                                                    onClick={() => setOpenAccountList(true)}
                                            />
                                        </div>
                                        <div className={"content__splitter"}/>
                                        <div className={"settings__row"}>
                                            <div className={"settings__label"}>
                                                Unsuccessfully login count: {settings.system.unsuccessfullyLoginCount}
                                            </div>
                                            <Button icon={ClearSharp}
                                                    waiting={waitingUpdateSettings === "login-count"}
                                                    onClick={() => {
                                                        onUpdateSettings(
                                                            {
                                                                system: {
                                                                    unsuccessfullyLoginCount: 0,
                                                                }
                                                            },
                                                            "login-count",
                                                        );
                                                    }}
                                            />
                                        </div>
                                        <div className={"content__splitter"}/>
                                        <Switch labelTx={"Enable exchanges reading job"}
                                                waiting={waitingUpdateSettings === "exchanges-job"}
                                                checked={!!settings.system.readExchangesPricesJobEnabled}
                                                onChange={(checked) => {
                                                    onUpdateSettings(
                                                        {
                                                            system: {
                                                                readExchangesPricesJobEnabled: checked,
                                                            }
                                                        },
                                                        "exchanges-job",
                                                    );
                                                }}
                                        />
                                        <Switch labelTx={"Enable twitter reading job"}
                                                waiting={waitingUpdateSettings === "twitter-job"}
                                                checked={!!settings.system.readTwitterJobEnabled}
                                                onChange={(checked) => {
                                                    onUpdateSettings(
                                                        {
                                                            system: {
                                                                readTwitterJobEnabled: checked,
                                                            }
                                                        },
                                                        "twitter-job",
                                                    );
                                                }}
                                        />
                                        <Switch labelTx={"Enable sms notifications job"}
                                                waiting={waitingUpdateSettings === "sms-notifications-job"}
                                                checked={!!settings.system.smsNotificationJobEnabled}
                                                onChange={(checked) => {
                                                    onUpdateSettings(
                                                        {
                                                            system: {
                                                                smsNotificationJobEnabled: checked,
                                                            }
                                                        },
                                                        "sms-notifications-job",
                                                    );
                                                }}
                                        />
                                    </div>
                                    <div className={"content__splitter"}/>
                                </>
                            }
                        </>
            }

            <Snackbar open={!!snackbarMessage}
                      message={snackbarMessage}
                      onClose={() => setSnackbarMessage("")}
                      autoHideDuration={3000}
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            />
        </div>
    </div>
}

const getServerOptions = (filterOut?: Server): SelectOptions[] => {
    const options: SelectOptions[] = [];
    Object.keys(Server).forEach((key) => {
        // @ts-ignore
        const value = Server[key]
        if (value !== filterOut) {
            const option: SelectOptions = {
                value,
                label: key,
            };
            options.push(option);
        }
    });
    return options;
}

const getScreenTypeOptions = (): SelectOptions[] => {
    const options: SelectOptions[] = [];
    Object.keys(ScreenType).forEach((key) => {
        // @ts-ignore
        const value = ScreenType[key];
        const option: SelectOptions = {
            value,
            label: key,
        };
        options.push(option);
    });
    return options;
}

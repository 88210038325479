import './account.css';
import {AccountProps} from "./account.props";
import React, {useCallback, useEffect, useState} from "react";
import {LogoutSharp, PaidSharp} from "@mui/icons-material";
import {serviceUserApi, UserDto} from "@coma/api/users";
import {AppModules, getAppModuleHeader} from "../app.modules";
import useServiceApi from "../../hooks/useServiceApi";
import {Button, Error, Header, ProgressIndicator} from "../../components";
import {AccountDetails} from "./account-details/account-details";

/**
 * Account module
 */
export const Account = function AccountModule(props: AccountProps) {
    const { setActiveAppModule, lastActiveAppModule, autoRefreshEnabled, onBack, onLogout, children } = props;
    const [ user, setUser ] = useState<UserDto>();
    const backTitle = getAppModuleHeader(lastActiveAppModule);

    const {
        execute: executeGetUser,
        waiting: waitingGetUser,
        error: errorGetUser,
    } = useServiceApi<UserDto>(serviceUserApi.getUser);

    const loadPage = useCallback(() => {
        executeGetUser()
            .then((userDto) => {
                setUser(userDto);
            })
    }, [ executeGetUser ]);

    useEffect(() => {
        setActiveAppModule(AppModules.ACCOUNT);
    }, [ setActiveAppModule, loadPage ]);

    useEffect(() => {
        loadPage();
    }, [ autoRefreshEnabled, loadPage ]);

    return <div className={"account"}>
        <div className={"account__content"}>
            {
                children ?
                    children
                    : <Header title={backTitle} onBack={onBack}/>
            }
            {waitingGetUser ?
                <ProgressIndicator/>
                :
                errorGetUser ?
                    <Error errorTx={errorGetUser} tryAgainAction={executeGetUser} onLogout={onLogout}/>
                    :
                    <>
                        {
                            user
                            && <>
                                <AccountDetails account={user}/>
                                <div className={"account__row"}>
                                    <span>Upgrade licence</span>
                                    <Button icon={PaidSharp}
                                            onClick={() => {
                                            }}
                                            noPadding
                                    />
                                </div>
                                <div className={"content__splitter"}/>
                                <div className={"account__row"}>
                                    <span>Logout</span>
                                    <Button icon={LogoutSharp}
                                            onClick={onLogout}
                                            noPadding
                                    />
                                </div>
                                <div className={"content__splitter"}/>
                            </>
                        }
                    </>
            }
        </div>
    </div>
}

import {AxiosRequestConfig} from "axios";
import {axios_get} from "../../common/services/api.configuration";
import {ExchangePricesDto} from "../dto/exchange.prices.dto";

export const EXCHANGES_PRICES_ROUTE = "/exchanges-prices"

class ExchangeApi {

    getExchangesPrices(): Promise<ExchangePricesDto[]> {
        const config: AxiosRequestConfig = {};
        return axios_get(EXCHANGES_PRICES_ROUTE, config);
    }
}

export const serviceExchangeApi = new ExchangeApi();

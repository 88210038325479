export enum AppModules {
    RULES = "rules",
    EXCHANGES = "exchanges",
    TWEETS = "tweets",
    NOTIFICATIONS = "notifications",
    SETTINGS = "settings",
    ACCOUNT = "account",
}

export const getAppModuleHeader = (module: AppModules) => {
    switch (module) {
        case AppModules.RULES:
            return "Event rules";
        case AppModules.EXCHANGES:
            return "Exchange prices";
        case AppModules.TWEETS:
            return "Related tweets";
        case AppModules.NOTIFICATIONS:
            return "Event notifications";
        case AppModules.SETTINGS:
            return "Settings";
        case AppModules.ACCOUNT:
            return "Account";
    }
    // return isMobileBrowser ? "Exchange prices" : "Bitgert Brise Invest";
}

import './App.css';
import {getScreenType, ScreenType} from "@coma/api/utils/screen-type";
import React, {useCallback, useEffect, useState} from 'react';
import {AccountCircleSharp, AutorenewSharp, SettingsSharp} from '@mui/icons-material';
import {
    axiosSetAuthToken,
    TOKEN_LOCAL_STORAGE_KEY
} from "@coma/api/common/services/api.configuration";
import {LicenseType, serviceUserApi, UserDto} from "@coma/api/users";
import {getLicenseType} from "@coma/api/utils/general-utils";
import useServiceApi from "../../hooks/useServiceApi";
import {useLocalStorage} from "../../hooks";
import {Dashboard} from "../dashboard";
import {Login} from "../login";
import {Settings} from "../../modules";
import {Account} from "../../modules/account/account";
import {Button, Error, ErrorBoundary, Header, IconButton, IconButtonSize, ProgressIndicator} from "../../components";
import {RootModules} from "./root.modules";
import {AppModules, getAppModuleHeader} from "../../modules/app.modules";

/**
 * App entry
 */
function App() {
    const isDevice = ScreenType.DEVICE === getScreenType();
    const [ token, setToken ] = useLocalStorage(TOKEN_LOCAL_STORAGE_KEY, "");
    const [ loading, setLoading ] = useState<boolean>(true);
    const [ activeRootModule, setActiveRootModule ] = useState<RootModules>(RootModules.DASHBOARD);
    const [ activeAppModule, setActiveAppModule ] = useState<AppModules>(AppModules.EXCHANGES);
    const [ lastAppModule, setLastAppModule ] = useState<AppModules>(AppModules.EXCHANGES);
    const [ user, setUser ] = useState<UserDto>();
    const appHeader = getAppModuleHeader(activeAppModule);
    const autoRefreshEnabled = !!user?.settings?.autoRefreshEnabled;
    const smsEnabled = !!user?.settings?.smsEnabled;
    const licenseType = getLicenseType(user);

    const {
        execute: executeGetUser,
        error: errorGetUser,
    } = useServiceApi<UserDto>(serviceUserApi.getUser);

    const getUser = useCallback((currentToken: string) => {
        if (currentToken) {
            executeGetUser()
                .then((userDto) => {
                    setUser(userDto);
                    setLoading(false);
                }).catch(() => setLoading(false));
        } else {
            setUser(undefined);
            setLoading(false);
        }
    }, [ executeGetUser ]);

    const getUserCallback = useCallback(() => {
        getUser(token);
    }, [ getUser, token ]);

    const openRootModule = useCallback((module: RootModules) => {
        if (activeRootModule !== module) {
            getUser(token);
        }
        setActiveRootModule(module);
    }, [ activeRootModule, setActiveRootModule, getUser, token ]);

    const openAppModule = useCallback((module: AppModules) => {
        if (activeAppModule !== module) {
            if (isDevice) {
                if (activeAppModule === AppModules.SETTINGS || activeAppModule === AppModules.ACCOUNT) {
                    setLastAppModule(AppModules.EXCHANGES);
                } else {
                    setLastAppModule(activeAppModule);
                }
            }
            setActiveAppModule(module);
        }
    }, [ isDevice, setLastAppModule, activeAppModule, setActiveAppModule ]);

    useEffect(() => {
        if (!user) {
            axiosSetAuthToken(token);
            getUser(token);
        }
    }, [ user, getUser, token ]);

    const onLogin = (token: string) => {
        setToken(token);
        openRootModule(RootModules.DASHBOARD);
    }

    const onLogout = () => {
        setToken("");
    }

    const onError = () => {
        setToken("");
    }

    return (
        <div className="app">
            <div className="app__container">
                <div className="app__header">
                    {token && <div className="app__header-content">
                        {isDevice ?
                            <>
                                <Button icon={SettingsSharp}
                                        onClick={() => openRootModule(RootModules.SETTINGS)}/>
                                <div>
                                    {appHeader}
                                </div>
                                <Button icon={AccountCircleSharp}
                                        onClick={() => openRootModule(RootModules.USER)}/>
                            </>
                            :
                            <>
                                <div/>
                                <div>
                                    Track specific events in real time
                                </div>
                                <div/>
                            </>
                        }
                    </div>
                    }
                </div>
                <ErrorBoundary onError={onError}>
                    <div className="app__content">
                        {errorGetUser ?
                            <div className="app__error">
                                <Error errorTx={errorGetUser} tryAgainAction={() => getUser(token)}
                                       onLogout={onLogout}/>
                            </div>
                            :
                            loading ?
                                <ProgressIndicator/>
                                :
                                token ?
                                    getContent(isDevice, autoRefreshEnabled, smsEnabled, licenseType, appHeader,
                                        activeRootModule, openRootModule, openAppModule, lastAppModule, getUserCallback, onLogout)
                                    :
                                    <Login onLogin={onLogin}/>
                        }
                    </div>
                </ErrorBoundary>
            </div>
        </div>
    );
}

const getContent = (isDevice: boolean,
                    autoRefreshEnabled: boolean,
                    smsEnabled: boolean,
                    licenseType: LicenseType,
                    appHeader: string,
                    activeRootModule: RootModules,
                    openRootModule: (module: RootModules) => void,
                    openAppModule: (module: AppModules) => void,
                    lastAppModule: AppModules,
                    getUserCallback: () => void,
                    onLogout: () => void) => {
    return <>
        {
            isDevice ?
                <>
                    {
                        activeRootModule === RootModules.DASHBOARD
                        && <Dashboard defaultAppModule={lastAppModule}
                                      autoRefreshEnabled={autoRefreshEnabled}
                                      smsEnabled={smsEnabled}
                                      licenseType={licenseType}
                                      setActiveAppModule={openAppModule}
                                      onLogout={onLogout}
                        />
                    }
                    {
                        activeRootModule === RootModules.SETTINGS
                        && <Settings licenseType={licenseType}
                                     setActiveAppModule={openAppModule}
                                     lastActiveAppModule={lastAppModule}
                                     onBack={() => openRootModule(RootModules.DASHBOARD)}
                                     onLogout={onLogout}
                        />
                    }
                    {
                        activeRootModule === RootModules.USER
                        && <Account setActiveAppModule={openAppModule}
                                    lastActiveAppModule={lastAppModule}
                                    autoRefreshEnabled={autoRefreshEnabled}
                                    onBack={() => openRootModule(RootModules.DASHBOARD)}
                                    onLogout={onLogout}
                        />
                    }
                </>
                :
                getWebDashboard(autoRefreshEnabled, smsEnabled, licenseType, openAppModule, lastAppModule, getUserCallback, onLogout)
        }
    </>
}


const getWebDashboard = (autoRefreshEnabled: boolean,
                         smsEnabled: boolean,
                         licenseType: LicenseType,
                         openAppModule: (module: AppModules) => void,
                         lastAppModule: AppModules,
                         getUserCallback: () => void,
                         onLogout: () => void) => {
    return <>
        <div className={"web-module__widget"}>
            <Account setActiveAppModule={dummyFunction}
                     lastActiveAppModule={lastAppModule}
                     autoRefreshEnabled={autoRefreshEnabled}
                     onBack={dummyFunction}
                     onLogout={onLogout}
            >
                <Header title={getAppModuleHeader(AppModules.ACCOUNT)}>
                    {
                        autoRefreshEnabled ?
                            <div className={"blink"}>
                                <IconButton icon={AutorenewSharp}
                                            size={IconButtonSize.SMALL}
                                            important
                                />
                            </div>
                            : undefined
                    }
                </Header>
            </Account>
            <div className={"web-module__inner-widget"}>
                <Settings setActiveAppModule={openAppModule}
                          lastActiveAppModule={lastAppModule}
                          licenseType={licenseType}
                          onBack={getUserCallback}
                          onLogout={onLogout}
                >
                    <Header title={getAppModuleHeader(AppModules.SETTINGS)}/>
                </Settings>
            </div>
        </div>
        <div className={"web-module__widget"}>
            <Dashboard defaultAppModule={AppModules.RULES}
                       autoRefreshEnabled={autoRefreshEnabled}
                       smsEnabled={smsEnabled}
                       licenseType={licenseType}
                       showToolbar={false}
                       setActiveAppModule={dummyFunction}
                       onLogout={onLogout}
            >
                <Header title={getAppModuleHeader(AppModules.RULES)}>
                    {
                        autoRefreshEnabled ?
                            <div className={"blink"}>
                                <IconButton icon={AutorenewSharp}
                                            size={IconButtonSize.SMALL}
                                            important
                                />
                            </div>
                            : undefined
                    }
                </Header>
            </Dashboard>
            <div className={"web-module__inner-widget"}>
                <Dashboard defaultAppModule={AppModules.NOTIFICATIONS}
                           autoRefreshEnabled={autoRefreshEnabled}
                           smsEnabled={smsEnabled}
                           licenseType={licenseType}
                           showToolbar={false}
                           setActiveAppModule={dummyFunction}
                           onLogout={onLogout}
                >
                    <Header title={getAppModuleHeader(AppModules.NOTIFICATIONS)}>
                        {
                            autoRefreshEnabled ?
                                <div className={"blink"}>
                                    <IconButton icon={AutorenewSharp}
                                                size={IconButtonSize.SMALL}
                                                important
                                    />
                                </div>
                                : undefined
                        }
                    </Header>
                </Dashboard>
            </div>
        </div>
        <div className={"web-module__widget"}>
            <Dashboard defaultAppModule={AppModules.EXCHANGES}
                       autoRefreshEnabled={autoRefreshEnabled}
                       smsEnabled={smsEnabled}
                       licenseType={licenseType}
                       showToolbar={false}
                       setActiveAppModule={dummyFunction}
                       onLogout={onLogout}
            >
                <Header title={getAppModuleHeader(AppModules.EXCHANGES)}>
                    {
                        autoRefreshEnabled ?
                            <div className={"blink"}>
                                <IconButton icon={AutorenewSharp}
                                            size={IconButtonSize.SMALL}
                                            important
                                />
                            </div>
                            : undefined
                    }
                </Header>
            </Dashboard>
        </div>
        <div className={"web-module__widget"}>
            <Dashboard defaultAppModule={AppModules.TWEETS}
                       autoRefreshEnabled={autoRefreshEnabled}
                       smsEnabled={smsEnabled}
                       licenseType={licenseType}
                       showToolbar={false}
                       setActiveAppModule={dummyFunction}
                       onLogout={onLogout}
            >
                <Header title={getAppModuleHeader(AppModules.TWEETS)}>
                    {
                        autoRefreshEnabled ?
                            <div className={"blink"}>
                                <IconButton icon={AutorenewSharp}
                                            size={IconButtonSize.SMALL}
                                            important
                                />
                            </div>
                            : undefined
                    }
                </Header>
            </Dashboard>
        </div>
    </>
}

const dummyFunction = () => {
    // prevents updating app modules
}

export default App;

import './tweets.css';
import {TweetsProps} from "./tweets.props";
import React, {useCallback, useEffect, useState} from "react";
import {TweetDto, serviceTweetsApi} from "@coma/api/twitter";
import {sanitizeText} from "@coma/api/utils/securityUtils";
import { AppModules } from '../app.modules';
import useServiceApi from "../../hooks/useServiceApi";
import {ALL_KEYWORD_OPTION, EmptyList, Error, getKeywordOptions, ProgressIndicator, Select} from "../../components";

/**
 * Twitter tweets module
 */
export const Tweets = function TweetsModule(props: TweetsProps) {
    const { loadCount, autoRefreshEnabled, setActiveAppModule, onLogout } = props;
    const [ tweets, setTweets ] = useState<TweetDto[]>();
    const [ keyword, setKeyword ] = useState<string>(ALL_KEYWORD_OPTION);
    const {
        execute: executeGetTweets,
        waiting: waitingGetTweets,
        error: errorGetTweets
    } = useServiceApi<TweetDto[]>(serviceTweetsApi.getTweets);

    const getTweets = useCallback(() => {
        executeGetTweets()
            .then((tweetsDto) => {
                setTweets(tweetsDto);
            })
    }, [ executeGetTweets ])

    useEffect(() => {
        setActiveAppModule(AppModules.TWEETS);
    }, [ setActiveAppModule, loadCount ]);

    useEffect(() => {
        getTweets();
        if (autoRefreshEnabled) {
            const interval = setInterval(getTweets, 1000 * 60);
            return () => clearInterval(interval);
        }
    }, [ autoRefreshEnabled, getTweets ]);

    return <div className={"tweets"}>
        {waitingGetTweets ?
            <ProgressIndicator/>
            :
            errorGetTweets ?
                <Error errorTx={errorGetTweets} tryAgainAction={getTweets} onLogout={onLogout}/>
                :
                tweets && tweets.length ?
                    <>
                        <Select labelTx={"Coin filter"}
                                value={keyword}
                                options={getKeywordOptions(tweets)}
                                onChange={(selectedKeyword: string) => setKeyword(selectedKeyword)}
                        />
                        <div className={"content__splitter"}/>
                        {
                            tweets.map((tweet) => {
                                if (keyword !== ALL_KEYWORD_OPTION && keyword !== tweet.keyword) {
                                    return undefined;
                                }
                                return <div key={tweet.textHash}>
                                    <div className={"tweets__entry"}>
                                        <div>
                                            {new Date(tweet.utcTime).toLocaleString()}
                                        </div>
                                        <div className={"tweets__entry__twitter-name"}>
                                            <div>{tweet.twitterName}</div>
                                            <div>origin: {tweet.origin}</div>
                                        </div>
                                        <div className={"tweets__entry__text"}
                                             dangerouslySetInnerHTML={{ __html: sanitizeText(tweet.text) }}
                                        />
                                    </div>
                                    <div className={"content__splitter"}/>
                                </div>
                            })
                        }
                    </>
                    :
                    <EmptyList titleTx={"No tweets..."}/>
        }
    </div>
}
